<template>
  <div>
    <div class="row">
      <div class="col-12">
        <!--<component v-for="(component, index) of content.column1" :key="index" :is="component" v-bind="component"/>-->
        <template v-for="(component, index) of content.column1" :key="index">
          <WidgetModel :component="component"></WidgetModel>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <template v-for="(component, index) of content.column2" :key="index">
          <WidgetModel :component="component"></WidgetModel>
        </template>
      </div>
      <div class="col-12 col-md-6">
        <template v-for="(component, index) of content.column3" :key="index">
          <WidgetModel :component="component"></WidgetModel>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <template v-for="(component, index) of content.column4" :key="index">
          <WidgetModel :component="component"></WidgetModel>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetModel from '@/components/Widgets/Shared/WidgetModel.vue';

export default {
  name: "AppLayout_12_6_6_12",
  data() {
    return {
      content: this.$route.meta && this.$route.meta.layout && this.$route.meta.layout.content  ? this.$route.meta.layout.content : '',
    };
  },
  components: {
    WidgetModel,
  },

}
</script>

<style scoped>
  .header {
    height: 5rem;
    background-color: green;
  }
</style>